import React, { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import useAuthUserStore from "../../lib/zustand/authUserStore";

const SecureLayout = ({ children, secure = true }) => {
    const navigate = useNavigate();
    const [showContent, setShowContent] = useState(false);
    const { authUser } = useAuthUserStore();

    useEffect(() => {
        const mobilePath = window.location.pathname
        const pdfPath = mobilePath.split("/")
        if (secure === true) {
            if (authUser && authUser?.accessToken && authUser?.userProfile) {
                setShowContent(true);
            } else if (pdfPath[1] === "GeneratePdf" || pdfPath[1] === "InvoicePdf") {
                setShowContent(true);
            } else {
                navigate("/")
            }
        } else {
            setShowContent(true);
        }
    }, [authUser, navigate, secure])


    return showContent ? children : <div></div>

}

export default SecureLayout;