import { create } from "zustand"

const useAuthUserStore = create((set) => ({
    authUser: null,
    setUserProfile: (profile) => set({ authUser: { profile: profile } }),
    setAccessToken: (token) => set((state) => ({ authUser: { ...state.authUser, accessToken: token } })),
    setAuthUser: (authUser) => set({ authUser: authUser }),
    resetAuthUser: () => set({ authUser: null })
}))

export default useAuthUserStore;