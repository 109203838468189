import React, { useEffect } from 'react';
// import 'antd/dist/antd.css';
// import './App.scss';
import './Styles.scss';
import { BrowserRouter } from 'react-router-dom';
import MyRoutes from "./Routes";
import { ConfigProvider } from 'antd';
import { CookiesProvider } from 'react-cookie';
import useAuthUserStore from './lib/zustand/authUserStore';
import { useCookies } from 'react-cookie';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import { DOCUMENT_TYPES } from './lib/contansts';
import { Toaster } from 'react-hot-toast';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

function App() {
  const { setAuthUser } = useAuthUserStore();
  const [cookies] = useCookies(["accessToken"]);

  useEffect(() => {
    if (cookies?.accessToken) {
      localStorage.setItem("accessToken", cookies?.accessToken)
      const decodedToken = jwtDecode(cookies?.accessToken);
      if (!decodedToken || !decodedToken.JsonString) return;
      const userProfile = JSON.parse(decodedToken.JsonString)

      // Check user status here
      if (userProfile.Status !== 1 || (userProfile.Status === 1 && (userProfile?.UserSubscription?.Status === 'past_due' || userProfile?.UserSubscription?.Status === 'expired'))) {
        // const restrictedPaths = [
        //   '/my-account',
        //   '/pricing',
        //   '/',
        //   '/stripeSuccess',
        //   '/stripeSuccess/Confirm/:session_id'
        // ];
        // const currentPath = window.location.pathname;
        // if (!restrictedPaths.includes(currentPath)) {
        //   window.location.href = "/";
        // }
        const restrictedPaths = [
          '/my-account',
          '/pricing',
          '/change-password',
          '/',
          '/stripeSuccess'
        ];
      
        // Regular expression to match paths like '/stripeSuccess/Confirm/:session_id'
        const confirmPathRegex = /^\/stripeSuccess\/Confirm\/\w+$/;
        
        const currentPath = window.location.pathname;
        
        // Check if the current path is not in the restricted paths list and 
        // it doesn't match the confirm path pattern
        if (!restrictedPaths.includes(currentPath) && !confirmPathRegex.test(currentPath)) {
            window.location.href = "/";
        }
      }
      const docsList = userProfile?.Organisation?.OrganisationInfo;
      const companyLogo = docsList?.find((doc) => doc.Type === DOCUMENT_TYPES.COMPANY_LOGO)
      const companyRegCertificate = docsList?.find((doc) => doc.Type === DOCUMENT_TYPES.COMPANY_REG_CERTIFICATE)
      const taxRegCertificate = docsList?.find((doc) => doc.Type === DOCUMENT_TYPES.TAX_REG_CERTIFICATE)
      const bankMaintenanceCertificate = docsList?.find((doc) => doc.Type === DOCUMENT_TYPES.BANK_MANTENANCE_CERTIFICATE)
      const membershipCertificate = docsList?.find((doc) => doc.Type === DOCUMENT_TYPES.MEMBERSHIP_CERTIFICATE)
      // console.log(userProfile)
      setAuthUser({
        accessToken: cookies.accessToken,
        userProfile: {
          ...userProfile,
          Organisation: {
            ...userProfile?.Organisation,
            logo: companyLogo && {
              ...companyLogo,
              src: companyLogo.Url + companyLogo.Image
            },
            documents: {
              companyRegCertificate: companyRegCertificate && {
                ...companyRegCertificate,
                src: companyRegCertificate.Url + companyRegCertificate.Image
              },
              taxRegCertificate: taxRegCertificate && {
                ...taxRegCertificate,
                src: taxRegCertificate.Url + taxRegCertificate.Image
              },
              bankMaintenanceCertificate: bankMaintenanceCertificate && {
                ...bankMaintenanceCertificate,
                src: bankMaintenanceCertificate.Url + bankMaintenanceCertificate.Image
              },
              membershipCertificate: membershipCertificate && {
                ...membershipCertificate,
                src: membershipCertificate.Url + membershipCertificate.Image
              },
            }
          }
        }
      });
      axios.defaults.headers.common["Authorization"] = "Bearer " + cookies.accessToken;
    } else if(localStorage.getItem("accessToken") !== undefined && localStorage.getItem("accessToken") !== null) {
      const token = localStorage.getItem("accessToken")
      const decodedToken = jwtDecode(token);
      if (!decodedToken || !decodedToken.JsonString) return;
      const userProfile = JSON.parse(decodedToken.JsonString)

      // Check user status here
      if (userProfile.Status !== 1 || (userProfile.Status === 1 && (userProfile?.UserSubscription?.Status === 'past_due' || userProfile?.UserSubscription?.Status === 'expired'))) {
        // const restrictedPaths = [
        //   '/my-account',
        //   '/pricing',
        //   '/',
        //   '/stripeSuccess',
        //   '/stripeSuccess/Confirm/:session_id'
        // ];
        // const currentPath = window.location.pathname;
        // if (!restrictedPaths.includes(currentPath)) {
        //   window.location.href = "/";
        // }
        const restrictedPaths = [
          '/my-account',
          '/pricing',
          '/change-password',
          '/',
          '/stripeSuccess'
        ];
      
        // Regular expression to match paths like '/stripeSuccess/Confirm/:session_id'
        const confirmPathRegex = /^\/stripeSuccess\/Confirm\/\w+$/;
        
        const currentPath = window.location.pathname;
        
        // Check if the current path is not in the restricted paths list and 
        // it doesn't match the confirm path pattern
        if (!restrictedPaths.includes(currentPath) && !confirmPathRegex.test(currentPath)) {
            window.location.href = "/";
        }
      }

      const docsList = userProfile?.Organisation?.OrganisationInfo;
      const companyLogo = docsList?.find((doc) => doc.Type === DOCUMENT_TYPES.COMPANY_LOGO)
      const companyRegCertificate = docsList?.find((doc) => doc.Type === DOCUMENT_TYPES.COMPANY_REG_CERTIFICATE)
      const taxRegCertificate = docsList?.find((doc) => doc.Type === DOCUMENT_TYPES.TAX_REG_CERTIFICATE)
      const bankMaintenanceCertificate = docsList?.find((doc) => doc.Type === DOCUMENT_TYPES.BANK_MANTENANCE_CERTIFICATE)
      const membershipCertificate = docsList?.find((doc) => doc.Type === DOCUMENT_TYPES.MEMBERSHIP_CERTIFICATE)
      // console.log(userProfile)
      setAuthUser({
        accessToken: token,
        userProfile: {
          ...userProfile,
          Organisation: {
            ...userProfile?.Organisation,
            logo: companyLogo && {
              ...companyLogo,
              src: companyLogo.Url + companyLogo.Image
            },
            documents: {
              companyRegCertificate: companyRegCertificate && {
                ...companyRegCertificate,
                src: companyRegCertificate.Url + companyRegCertificate.Image
              },
              taxRegCertificate: taxRegCertificate && {
                ...taxRegCertificate,
                src: taxRegCertificate.Url + taxRegCertificate.Image
              },
              bankMaintenanceCertificate: bankMaintenanceCertificate && {
                ...bankMaintenanceCertificate,
                src: bankMaintenanceCertificate.Url + bankMaintenanceCertificate.Image
              },
              membershipCertificate: membershipCertificate && {
                ...membershipCertificate,
                src: membershipCertificate.Url + membershipCertificate.Image
              },
            }
          }
        }
      });
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
  }, [cookies, setAuthUser])

  return (
    <BrowserRouter>
      <ConfigProvider
        theme={{
          token: {
            fontFamily: 'Poppins',
            colorPrimary: '#F49A26',
          },
        }}>
        <CookiesProvider>
          <Toaster
            position="top-right"
            toastOptions={{ className: "react-hot-toast" }}
          />
          <MyRoutes />
          <TawkMessengerReact
            propertyId="64f5b9dda91e863a5c118403"
            widgetId="1h9fts2tl"/>
        </CookiesProvider>
      </ConfigProvider>
    </BrowserRouter>
  );

}

export default App;