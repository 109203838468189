

export const SESSIONS = {
    USER_SIGNUP_DETAILS: "user-signup-details",
    LOGGED_IN: "logged-in"
}

export const REGEX_PATTERNS = {
    EMAIL: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
}


export const CONSTANTS = {
    UNKNOWN_ERROR_TRY_AGAIN: "Some error occured, while processing your request. Please try again."
}


export const DOCUMENT_TYPES = {
    COMPANY_LOGO: "CompanyLogo",
    COMPANY_REG_CERTIFICATE: "CompanyRegistrationCertificate",
    TAX_REG_CERTIFICATE: "TaxRegistrationCertificate",
    BANK_MANTENANCE_CERTIFICATE: "BankAccountMaintenanceCertificate",
    MEMBERSHIP_CERTIFICATE: "MembershipCertificate",
}
